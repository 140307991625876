import React, { useState, useEffect } from 'react';

const ProgressBar = (props) => {
const [progress, setProgress] = useState(100);
const [timer, setTimer] = useState(10);

useEffect(() => {
    const intervalId = setInterval(() => {
        setProgress(prevProgress => prevProgress - 11); // 11 per 10 secondi (ritardo 1000)
        setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => {
        clearInterval(intervalId);
    };
}, []);

useEffect(() => {
    if (progress <= 0) {
        props.Logout();
    }
}, [progress]);

return (
    <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <p className='progress-bar-n'>{timer}</p>
    </div>
    );
};

export default ProgressBar;
