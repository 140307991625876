
import React from 'react';
import { useState } from 'react';
import groupman from '../imgs/Groupman.svg';
import red_dot from '../imgs/red-circle.svg';

const Target = (props) => {
    const [show, setShow] = useState(false);
    
    const targetStyle = {
        top: `${props.target.yPosCm}px`,
        left: `${props.target.xPosCm}px`,
        width: `35px`,
        height: `35px`,
        position: 'absolute',
        
    };
    
    //display groupman and while hovering it display x and y and z position

    return (
        <>
            <img src={red_dot} style={targetStyle} alt="groupman" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}></img>
            {show === true ? <div style={{position: "absolute", width:"150px", height: "20px", top: props.target.yPosCm - 20 ,  left: props.target.xPosCm, color: "black", backgroundColor: "grey", opacity: "0.7", fontSize: "1em", borderRadius: "20px", textAlign:"center"}}>X:{props.real.xPosCm}, Y:{props.real.yPosCm}, Z:{props.real.zPosCm}</div> : null}
        </>
        
    );

    
}

export default Target;
