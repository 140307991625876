import React, { useEffect } from "react";
import cpu from "../imgs/cpu.svg";

import busybed from "../imgs/busybed.svg";
import freebed from "../imgs/freebed.svg";
import busybathroom from "../imgs/busybathroom.svg";
import freebathroom from "../imgs/freebathroom.svg";
import busypassage from "../imgs/busypassage.svg";
import freepassage from "../imgs/freepassage.svg";
import busyunknown from "../imgs/busyunknown.svg";
import freeunknown from "../imgs/freeunknown.svg";
import busybathroomwithlowrssi from "../imgs/busybathroomwithlowrssi.svg";
import freebathroomwithlowrssi from "../imgs/freebathroomwithlowrssi.svg";
import busybedwithlowrssi from "../imgs/busybedroomwithlowrssi.svg";
import freebedwithlowrssi from "../imgs/freebedroomwithlowrssi.svg";
import busypassagewithlowrssi from "../imgs/busypassagewithlowrssi.svg";
import freepassagewithlowrssi from "../imgs/freepassagewithlowrssi.svg";
import busyunknownwithlowrssi from "../imgs/busyunknownwithlowrssi.svg";
import freeunknownwithlowrssi from "../imgs/freeunknownwithlowrssi.svg";
import offlinelivingroom from "../imgs/offlinelivingroom.svg";
import offlinelivingroomwithlowrssi from "../imgs/offlinelivingroomwithlowrssi.svg";
import offlinebedroomwithlowrssi from "../imgs/lowRSSiBed.svg";
import offlinepassagewithlowrssi from "../imgs/lowRSSIPassage.svg";
import offlinebathroomwithlowrssi from "../imgs/lowRSSIBathroom.svg";
import offlineunknownwithlowrssi from "../imgs/lowRSSiUnknown.svg";
import busylivingroom from "../imgs/busylivingroom.svg";
import busylivingroomwithlowrssi from "../imgs/busylivingroomwithlowrssi.svg";
import freelivingroom from "../imgs/freelivingroom.svg";
import freelivingroomwithlowrssi from "../imgs/freelivingroomwithlowrssi.svg";
import offlinebed from "../imgs/offlinebedroom.svg";
import offlinebathroom from "../imgs/offlinebathroom.svg";
import offlinepassage from "../imgs/offlinepassage.svg";
import offlineunknown from "../imgs/offlineunknown.svg";
import busyroomwithlowrssi from "../imgs/busyroomwithlowrssi.svg";
import busyroom from "../imgs/busyroom.svg";

import api from "../APi/Sensor/sensor";
import sensor from "../APi/Sensor/sensor";
const token = localStorage.getItem("token");

const Devices = (props) => {


    //console.info(props);
    const sensorid = props.sensor.id;
    const [sensorState, setSensorState] = React.useState({});
    const [sensorImg, setSensorImg] = React.useState("");
    //const [sensorid, setSensorid] = React.useState(props.sensor.id);

    function getImage(event, status, tipo) {
        //console.log("get Image: " + event + " " + status);
        switch (tipo) {

        case "Bedroom":
            if (status === "offline" && props.sensor.rssi > -70) {
                return offlinebed;
            }
            if (status === "offline" && props.sensor.rssi <= -70) {
                return offlinebedroomwithlowrssi;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freebed;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freebedwithlowrssi;
            }
            if (event === "3" && props.sensor.rssi > -70) {
                return busybed;
            }
            if (event === "3" && props.sensor.rssi <= -70) {
                return busybedwithlowrssi;
            }
            if (event === "4" && props.sensor.rssi > -70) {
                return busyroom;
            }
            if (event === "4" && props.sensor.rssi <= -70) {
                return busyroomwithlowrssi;
            }
                break;
            case "Bathroom":

            if (status === "offline" && props.sensor.rssi > -70) {
                return offlinebathroom;
            }
            if (status === "offline" && props.sensor.rssi <= -70) {
                return offlinebathroomwithlowrssi;
            }
            // when passing from offline to online the event for some seconds is at 0
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freebathroom;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freebathroomwithlowrssi;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi > -70) {
                return busybathroom;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi <= -70) {
                return busybathroomwithlowrssi;
            }

            break;

            case "Passage":

            if (status === "offline" && props.sensor.rssi > -70) {
                return offlinepassage;
            }
            if (status === "offline" && props.sensor.rssi <= -70) {
                return offlinepassagewithlowrssi;
            }
            // when passing from offline to online the event for some seconds is at 0
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freepassage;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freepassagewithlowrssi;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi > -70) {
                return busypassage;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi <= -70) {
                return busypassagewithlowrssi;
            }

            break;

            case "Other":

            if (status === "offline" && props.sensor.rssi > -70) {
                return offlineunknown;
            }
            if (status === "offline" && props.sensor.rssi <= -70) {
                return offlineunknownwithlowrssi;
            }
            // when passing from offline to online the event for some seconds is at 0
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freeunknown;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freeunknownwithlowrssi;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi > -70) {
                return busyunknown;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi <= -70) {
                return busyunknownwithlowrssi;
            }

            break;

            case "Diningroom":

            if (status === "offline" && props.sensor.rssi > -70) {
                return offlinelivingroom;
            }
            if (status === "offline" && props.sensor.rssi <= -70) {
                return offlinelivingroomwithlowrssi;
            }
            // when passing from offline to online the event for some seconds is at 0
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freelivingroom;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freelivingroomwithlowrssi;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi > -70) {
                return busylivingroom;
            }
            if ((event === "3" || event === "4") && props.sensor.rssi <= -70) {
                return busylivingroomwithlowrssi;
            }

            break;

            default:

            if (status === "offline") {
                return offlineunknown;
            }
            // when passing from offline to online the event for some seconds is at 0
            if ((event === "0" || event === "1") && props.sensor.rssi > -70) {
                return freeunknown;
            }
            if ((event === "0" || event === "1") && props.sensor.rssi <= -70) {
                return freeunknownwithlowrssi;
            }
            if (event === "3" && props.sensor.rssi > -70) {
                return busyunknown;
            }
            if (event === "3" && props.sensor.rssi <= -70) {
                return busyunknownwithlowrssi;
            }
            break;
        }
    }

    const handleSearch = (e) => {
        //setSearchTerm(e.target.value);
    };

    const handleDeviceClick = (device) => {
        // Implement device configuration logic here
        // console.log('Configure device:', device);
    };
    const getDeviceConfig = async (sensor_id) => {
        //get the device configuration
        try {
        const data = await sensor.getSensorConfig(props.token, sensor_id);
        //console.log(data);
        return data;
        } catch (error) {
        //props.logout()
        // console.log(error);
        }
    };

    return (
        // crea una tabella a card con i dati dei sensori
        <div
            id="Griglia"
            onClick={async () => {
                await props.openPopup(
                    "device",
                    await getDeviceConfig(props.sensor.id),
                    props.mqtt,
                    props.logout,
                    props.serial,
                    props.token,
                    props.sensor.eventTarget,
                    props.sensor.rssi,
                    props.mac
                );
            }}
            className="col-xs-5 col-xm-6 col-lg-2"
        >
        <table>
            <tbody>
                <tr>
                    {props.sensor.Struttura !== undefined ? (
                    <td
                        bgcolor="#F2F5FF"
                        width="100%"
                        style={{ wordWrap: "break-word" }}
                    >
                        <div
                        style={{
                            height: "20px",
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                        >
                        {props.sensor.Struttura}
                        </div>
                    </td>
                    ) : (
                    <td
                        bgcolor="#F2F5FF"
                        width="100%"
                        style={{ fontSize: "1em", maxWidth: "10px" }}
                    >
                        <div style={{ height: "1px" }}>Non assegnato</div>
                    </td>
                    )}
                </tr>
                <tr>
                    <td width="100%">
                    <div style={{ height: "80px" }}>
                        {props.sensor.Stanza !== undefined &&
                        props.sensor.Piano !== undefined &&
                        props.sensor.Struttura !== undefined ? (
                        <>
                            {props.sensor.Piano} | {props.sensor.Stanza}
                            <br />
                            <span
                            onClick={(e) => {
                                e.stopPropagation(); // Ferma la propagazione dell'evento
                                navigator.clipboard
                                    .writeText(props.sensor.id)
                                    .then(() => alert("ID copiato negli appunti!"))
                                    .catch((err) =>
                                        console.error("Errore nel copiare il testo: ", err)
                                    );
                            }}
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                            >
                            {props.sensor.id}
                            </span>
                            <br />
                            <span
                            onClick={(e) => {
                                e.stopPropagation(); // Ferma la propagazione dell'evento
                                navigator.clipboard
                                    .writeText(props.mac)
                                    .then(() => alert("MAC copiato negli appunti!"))
                                    .catch((err) =>
                                        console.error("Errore nel copiare il testo: ", err)
                                    );
                            }}
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                            >
                            {props.mac}
                            </span>
                        </>
                        ) : (
                            <>
                            <span>{props.sensor.serial}</span>
                            <br/>
                            <span
                            onClick={(e) => {
                                e.stopPropagation(); // Ferma la propagazione dell'evento
                                navigator.clipboard
                                    .writeText(props.mac)
                                    .then(() => alert("MAC copiato negli appunti!"))
                                    .catch((err) =>
                                        console.error("Errore nel copiare il testo: ", err)
                                    );
                            }}
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                            >
                            {props.mac}
                            </span>
                            </>
                        )}
                        {/* {console.log(props.sensor.id)} */}
                    </div>
                    {/*<div style={{ height: "20px" }}>last seen: {props.onlineTimestamp}</div>*/}
                    <div style={{ height: "20px" }}>
                        {props.sensor.status === "offline" 
                        ? <p style={{color:"red"}}>offline: {props.offlineTimestamp}</p> 
                        : <p style={{color:"blue"}}>last seen: {props.onlineTimestamp}</p>}

                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <table style={{ borderCollapse: "inherit" }}>
            <tbody>
                <tr>
                    <td width="50%">
                    <img src={cpu} alt="firmware" style={{ marginTop: "20px" }} />
                    </td>
                    <td width="50%">
                    <div className="fw" style={{ marginTop: "20px" }}>
                        &ensp;{props.sensor.fwversion}
                    </div>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        paddingTop: "3em",
                    }}
                    >
                    <img
                        src={getImage(props.sensor.event, props.sensor.status, props.sensor.Tipo)}
                        style={{
                        width: "4em",
                        paddingTop: "0.5em",
                        paddingLeft: "1.2em",
                        }}
                    />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  );
};

export default Devices;
