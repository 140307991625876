import React from 'react';
import { useDrag } from 'react-dnd';

const bedSource = {
    beginDrag(props) {
    // Define the data that will be passed when dragging starts
    return {
        type: 'bed', // A unique identifier for the draggable type
        id: props.id, // Add any relevant ID or data here
    };},
};

function Bed({ width, height, depth, marginLeft, marginTop, index }) {
    if (width < 0) width = width * -1;

    const [{ isDragging }, drag] = useDrag({
        type: 'bed', // Same as the type defined in beginDrag
        item: { id: index }, // Pass the index of the bed as payload
    });

    const bedStyle = {
        width: `${width}px`,
        height: `${height}px`,
        depth: `${depth}px`,
        backgroundColor: '#f0f0f0',
        marginTop: `${marginTop}px`,
        marginLeft: `${marginLeft}px`,
        opacity: isDragging ? 0.5 : 1, // Apply opacity when dragging
        cursor: 'move', // Change cursor style when dragging
    };

    return (
        <div ref={drag} // Make the component draggable
            className="bed" style={bedStyle} index={"prova"} id='prova'>
            {// show hello while dragging
                isDragging ? 'hello' : null
            }
        </div>
    );
}

export default Bed;
