import React, { useState } from "react";
import arrow from "../imgs/icons/arrowFull.svg";
import SchedaSensore from "./SchedaSensore";
import close from "../imgs/icons/close.svg";
import confirmW from "../imgs/icons/confirmW.svg";
import ProgressBar from "./ProgressBar";
//import langSet from "../lang/lang";



const PopupComp = (props) => {
    let handleContent = null;
    let bodyContent = null;
    let footerContent = null;
    let mod = 0
    let id = 0
    let name = ''
    //console.log(props);
    switch (props.info[0].category) {
        case 'device':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("device", '', '', props.info[0].data, props.info[0].mqttclient)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{"Scheda Sensore"}</div>
                </>
            );
            bodyContent = (
                <>
                    <SchedaSensore closePopup={props.closePopup} loading={props.loading} sensore={props.info[0].data} getSens={props.getSens} SchedaInScheda={props.SchedaInScheda} mqttclient={props.info[0].mqttclient} logout={props.Logout} serial={props.info[0].serialProduct} refreshLogin= {props.refresgLogin} token= {props.info[0].token} eventTarget={props.info[0].eventTarget} rssi={props.info[0].rssi} mac={props.info[0].mac}/>
                </>
            );
            break;
        case 'alarm':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_utente" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_utente", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_struttura" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_struttura", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_stanza" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_stanza", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_sensore" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_sensore", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>Avviso</div>
                </>
            );
            bodyContent = (
                <>
                    <div id="PopupBody">
                        {props.info[0].info}
                    </div>
                </>
            );
            console.log(props.info[0].operation);
            console.log(props.info);
            if (props.info[0].operation === "logout") {
                
                footerContent = (
                    <>
                        <div className="selettore selettoreFlex selettoreBlue" onClick={async () => {await props.Logout(); await props.mslogout()}} >
                            <p>Esci ora</p>
                            <img src={confirmW} alt="confirm" />
                        </div>
                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                            <p>Annulla</p>
                            <img src={close} alt="close" />
                        </div>
                        <br />
                        <ProgressBar Logout={props.Logout} />
                    </>
                );
            }
            
            break;

        default:
            break;
    }
    return (
        <>
        <div id="handle">
            {props.info[0].operation !== "logout" ? <img src={close} alt="close" className="closePopup" onClick={() => props.closePopup()} /> : null}
            {handleContent}
        </div>
        <div id="PopupBody"
             // style={{ width: props.info[0].category !== "alarm" ? "85em" : "30em" }}
        >
            {bodyContent}
            {footerContent}
        </div>
    </>
        );
}

export default PopupComp;
