
import SensoriComp from '../components/Devices';
import React, { useEffect, useState, useRef } from 'react';
import PopupComp from '../components/Popup.js';
import sensor from '../APi/Sensor/sensor';
import arrow from '../imgs/icons/arrow.svg';
import logo from "../imgs/logoGray.png";
import Menu from "../components/Menu.js";
import logout from '../imgs/icons/logout.svg';
import { Client } from 'paho-mqtt';
import dipasApi from '../APi/DiPas/dipasApi';

let timeoutFetch;

export default function Index(props) {
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState("");
    const [infosPopup, setInfosPopup] = useState(['']);
    const [devices, setDevices] = useState([]);
    const [strutturaSel, setStruttura] = useState(localStorage.getItem("struttura") === null ? JSON.stringify({"id":"All", "configurator_name":"All", "name":"all"}) : localStorage.getItem("struttura"));
    const [pianoSel, setPiano] = useState(localStorage.getItem("piano") === null ? JSON.stringify({nome: "All", value: "All"}) : localStorage.getItem("piano"));
    const [tipostanzaSel, setTipostanza] = useState(localStorage.getItem("tipostanza"));
    const [statoSel, setStato] = useState(localStorage.getItem("stato"));
    const [numeroDevices, setNumeroDevices] = useState(0);
    const [onlineDevices, setonlineDevices] = useState(0);
    const [offlineDevices, setofflineDevices] = useState(0);
    const [mqttclient, setMqttclient] = useState();
    const [ListaStrutture, setListaStrutture] = useState([]);
    const [ListaPiani, setListaPiani] = useState([]);
    const divRef = useRef(null);
    const divRefStruttura = useRef(null);
    const divRefPiano = useRef(null);
    const divRefTipostanza = useRef(null);
    const divRefStato = useRef(null);


    let accountData = JSON.parse(localStorage.getItem("account"));

    let token = props.accountInfo.jwtIdToken;

    const ListaTipiStanza = [
        {nome: "All", value: "All"},
        {nome: "Bagno", value: "Bathroom"},
        {nome: "Letto", value: "Bedroom"},
        {nome: "Soggiorno", value: "Diningroom"},
        {nome: "Cucina", value: "Kitchen"},
        {nome: "Varco", value: "Passage"},

    ]

    const ListaStati = [
        {nome: "All", value: "All"},
        {nome: "offline", value: "offline"},
        {nome: "online", value: "online"},
    ]

    useEffect(() => {
        (async () => {
            await getlistaStrutture();
            await getlistaPiani();
        }
        )();
    }, []);

    useEffect(() => {
        // Function to handle clicks outside the div
        function handleClickOutside(event) {
            if (divRefStruttura.current && !divRefStruttura.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("struttura");
            }
            if (divRefPiano.current && !divRefPiano.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("piano");
            }
            if (divRefTipostanza.current && !divRefTipostanza.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("tipostanza");
            }
            if (divRefStato.current && !divRefStato.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("stato");
            }
            if (divRef.current && !divRef.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("account");
            }

        }

        // Add event listener for clicks on the entire document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {

        if (localStorage.getItem("struttura") === undefined || localStorage.getItem("struttura") === "" || localStorage.getItem("struttura") === null || localStorage.getItem("struttura") === "null" || localStorage.getItem("struttura") === "undefined") {
            const strutturaSelezionata = JSON.stringify({name: "All", id: "All", configurator_name: "All"});
            localStorage.setItem("struttura", JSON.stringify({name: "All", id: "All"}));
            setStruttura(strutturaSelezionata );
        }

        if (localStorage.getItem("piano") === null) {
            //console.log("piano null");
            localStorage.setItem("piano", JSON.stringify({nome: "All", value: "All"}));
            setPiano(JSON.stringify({nome: "All", value: "All"}));

        }

        if (localStorage.getItem("tipostanza") === null) {
            localStorage.setItem("tipostanza", JSON.stringify({nome: "All", value: "All"}));
            setTipostanza(JSON.stringify({nome: "All", value: "All"}));
        }

        if (localStorage.getItem("stato") === null) {
            localStorage.setItem("stato", JSON.stringify({nome: "All", value: "All"}));
            setStato(JSON.stringify({nome: "All", value: "All"}));
        }

        getlistaPiani();
        async function fetch() {
            await getDash();
        }
        fetch();
        streamData();

        function resetTimeout(limit) {
            timeoutFetch = limit;
        }

        const id = setInterval(() => {
            if (timeoutFetch == 0 || timeoutFetch == null) {
                if (timeoutFetch == 0) {
                     fetch();
                }
                if(JSON.parse(strutturaSel).id === "All") {
                    resetTimeout(60);
                } else {
                    resetTimeout(30);
                }
            }
            timeoutFetch--;
        }, 1000);
        return () => {
            //console.log("non chiedo più");
            clearInterval(id);
        }

    }, []);
    async function getlistaPiani() {
        try {
            if (JSON.parse(strutturaSel).id !== "All" && JSON.parse(strutturaSel).id !== null && JSON.parse(strutturaSel).id !== undefined) {

                var db = JSON.parse(strutturaSel).id !== undefined ? JSON.parse(strutturaSel).id : JSON.parse(localStorage.getItem("struttura")).id;
                dipasApi.getPiani(token, db).then((piani) => {
                const arrayPiani = [];
                for (let i = 0; i < piani.length; i++) {
                  arrayPiani.push({nome: piani[i].descrizione, id: piani[i].id});
                }
                arrayPiani.unshift({nome: "All", id: "All"});
                //console.log(arrayPiani);
                setListaPiani(arrayPiani);
                });
            }
        } catch (error) {
        //console.log(error);
        }
    }
    async function getlistaStrutture() {
        try {
            const strutture = await dipasApi.getStrutture(token);
            //console.log(strutture);
            const lista = strutture.sort(function (a, b) {
                return a.configurator_name.localeCompare(b.configurator_name, undefined, { sensitivity: 'base' });
            });
            lista.unshift({ configurator_name: "All", name: "All", id: "All" });
            lista.unshift({ configurator_name: "Non assegnati", name: "Non assegnati", id: "Non assegnati" });
            const lista2 = lista.filter(elem => !elem.configurator_name.startsWith('~'));
            setListaStrutture(lista2);
        } catch (error) {
            console.log(error);
        }
    }
    function Slogga() {
        openPopup("alarm", "logout automatico", "logout");
    }
    async function Logout() {
        try {
            //window.location.reload();
            localStorage.removeItem("token");
            localStorage.setItem("login", false);
            localStorage.removeItem("account");
            localStorage.removeItem("struttura");
            //window.location.reload();
            props.logout()

            //window.location.href = "/";
        } catch (error) {
            //console.log(error);
        }

    }
    function refreshLogin() {
        localStorage.setItem("token", "expired");
        localStorage.setItem("login", false);
        localStorage.removeItem("account");
        localStorage.removeItem("struttura");
        props.login()
    }


    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        const mslogout = props.logout
        setInfosPopup([{ category, info, operation, data, mslogout }]);
    }

    async function streamData() {

        let client = new Client(process.env.REACT_APP_MQTT_URL, parseInt(process.env.REACT_APP_MQTT_PORT), "web_" + parseInt(Math.random() * 100, 10));
        let boolSSL = (/true/).test(process.env.REACT_APP_MQTT_SSL);
        client.connect({
            password: process.env.REACT_APP_MQTT_PASSWORD,
            userName: process.env.REACT_APP_MQTT_USER,
            useSSL: boolSSL,
        onSuccess: () => {
            //console.log("connected");
            // Now that we are connected, subscribe to topics here
            client.subscribe("/devices/+/dashboard", {
                onSuccess: () => {
                    //console.log("Subscribed to all topics using '#' wildcard");
                },
                onFailure: (e) => {
                    //console.log("Subscription failed", e);
                }
            });
        },
        onFailure: (e) => {
            //console.log("connect failed", e);
        }
        });
        setMqttclient(client);
        client.onMessageArrived = (message) => {
            setDevices((prevState) => {
                const newDevices = prevState.map((device) => {
                    if (device.id === message.destinationName.split("/")[2]) {
                        ////console.log(device);
                        return {...device, event: message.payloadString, tipo: device.tipo};

                    } else {
                        return {...device};
                    }
                    });
                    if (newDevices !== prevState) {
                        return newDevices;
                    }
                }
            );
        }
    }

    // console(pianoSel);
    
    async function getDash() {

        try {
            //console.log(strutturaSel.id, pianoSel, tipostanzaSel, statoSel)
            localStorage.setItem("token", token);

            //console.log("chiedo i sensori");
            let sensorList = await sensor.getSensors(token, JSON.parse(localStorage.getItem("struttura")).name, JSON.parse(pianoSel).id, JSON.parse(tipostanzaSel).value, JSON.parse(statoSel).value);

            if (sensorList.error === "Unauthorized") {
                refreshLogin();
            }

            const sensors = [];
            //console.log(sensorList);
            let onlineDevices = 0;
            let offlineDevices = 0;
            let devicescount = 0;
            if (sensorList !== undefined) {
                for (let i = 0; i < sensorList.length; i++) {
                    if (!sensorList[i].status) {
                        sensorList[i].status = "offline";
                    }
                    sensors[i] = {
                        Piano: sensorList[i].deviceInfo.Piano,
                        Stanza: sensorList[i].deviceInfo.Stanza,
                        Struttura: sensorList[i].deviceInfo.Struttura,
                        Tipo: sensorList[i].deviceInfo.Tipo,
                        id: sensorList[i].deviceInfo.deviceId,
                        serial: sensorList[i].deviceInfo.serialProduct,
                        rssi: sensorList[i].rssi,
                        event: sensorList[i].event !== undefined ? sensorList[i].event.toString() : undefined,
                        eventTarget: sensorList[i].eventTarget,
                        name: "none",
                        status: sensorList[i].status,
                        offlineTimestamp: sensorList[i].offlineTimestamp,
                        onlineTimestamp: sensorList[i].onlineTimestamp,
                        mac: sensorList[i].mac,
                        message: "no message",
                        fwversion: sensorList[i].deviceInfo.currentApkMetadata.versionCode,
                    }
                    setDevices(sensors.sort((a, b) => (a.Stanza > b.Stanza) ? 1 : -1));
                    //conteggio i sensori online

                    if (sensorList[i].status === "online") {
                        onlineDevices = onlineDevices + 1;
                    }
                    devicescount = devicescount + 1;
                }

                setonlineDevices(onlineDevices);
                setofflineDevices(devicescount - onlineDevices);
            } else if (sensorList === undefined) {
                window.location.reload();
            }
            ////console.log(sensors);
        } catch (error) {
            //console.log(strutturaSel);
            window.location.reload();
            //console.log(error);
        }

    }
    async function openPopup2(category, data, mqttclient, logout, serial, token, eventTarget, rssi, mac) {
        document.getElementById("Popup").style.display = "flex";
        //setta category e data in infosPopup
        let serialProduct = serial
        setInfosPopup([{ category, data,  mqttclient, logout, serialProduct, token, eventTarget, rssi, mac}]); // to be continued
    }
    function SchedaInScheda(category, info, operation, data, mqttclient, previousPage) {
        setInfosPopup([{ category, info, operation, data, mqttclient, previousPage }]);
    }
    function closePopup() {
        document.getElementById("Popup").style.display = "none";
        setInfosPopup(['']);
        getDash();
    }

    function openSottoSel(id) {
        let sottoSel = document.getElementById(id);
        if (sottoSel.style.opacity === '0' && sottoSel.style.display !== 'block') {
            sottoSel.style.display = "block";
            setTimeout(() => {
            sottoSel.style.opacity = 1;
            }, 0);
        } else {
            sottoSel.style.opacity = 0;
            setTimeout(() => {
            sottoSel.style.display = "none";
            }, 200);
        }
    }
    function closeSottoSel() {

        let sottoSel = document.getElementById("struttura");
        let sottoSel2 = document.getElementById("piano");
        let sottoSel3 = document.getElementById("tipostanza");
        let sottoSel4 = document.getElementById("stato");
        let sottoSel5 = document.getElementById("account");
        if (sottoSel.style.opacity === '1' && sottoSel.style.display !== 'none') {
        sottoSel.style.opacity = 0;
            setTimeout(() => {
                sottoSel.style.display = "none";
            }, 200);
        }
        if (sottoSel2 !== null && sottoSel2.style.opacity === '1') {
            sottoSel2.style.opacity = 0;
            setTimeout(() => {
                sottoSel2.style.display = "none";
            }, 200);
        }
        if (sottoSel3.style.opacity === '1') {
          sottoSel3.style.opacity = 0;
          setTimeout(() => {
              sottoSel3.style.display = "none";
          }
          , 200);
        }
        if (sottoSel4.style.opacity === '1') {
          sottoSel4.style.opacity = 0;
          setTimeout(() => {
              sottoSel4.style.display = "none";
          }
          , 200);
        }
        if (sottoSel5.style.opacity === '1') {
            sottoSel5.style.opacity = 0;
            setTimeout(() => {
                sottoSel5.style.display = "none";
            }, 200);
        }
    }
    //
    // useEffect(() => {
    //     console.log(pianoSel);
    //     console.log('ciao');
    // }, [pianoSel]);


    function resetfilter() {
        // console.log("function resetfilter");
        //
        // console.log("testtt");
        // pianoSel(null);
        // setPiano(null);
        // {"nome":"All","id":"All"}

        // console.log(pianoSel);

        setPiano(JSON.stringify({nome: "All", id: "All"}));

        // console.log(pianoSel);

        // setPiano(null);

        window.location.reload();

        // window.location.reload()
    }

    const MONTHS = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];


    function timestamp(t) {
        var date = new Date(t);
        var hours = date.getHours();
        var minutes = "0" + date.getMinutes();
        var seconds = "0" + date.getSeconds();
        var days = "" + date.getDate();
        var month = date.getMonth();
        var year = "" + date.getFullYear();
        var formattedTimeOnline = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + ' ' + days.substr(-2) + 'th ' + MONTHS[month] + ' ' + year.substr(-2);
        return formattedTimeOnline;
    }

    return (
        <>
        <div id= "Base">
            <div id="Content">
                <div id="PopupHeader"></div>
                <div id="Header" style={{background: "rgba(43, 49, 138)"}}>

                    <div className='headerSX' style={{marginLeft: 0}}>
                        <img src={logo} className="main-logo" alt="CARE4U 2.0"/>
                    </div>
                    <div className='headerCX'>
                        <div className='selettore selettoreFlex'
                             style={{width: "auto", minWidth: "10em"}}
                            // style={{ position: "absolute" }}
                             ref={divRefStruttura} onClick={() => openSottoSel("struttura")}>
                            <p>{strutturaSel === null ? "Seleziona struttura" : JSON.parse(strutturaSel).configurator_name}</p>&nbsp;
                            <img src={arrow} alt='arrow'/>
                            {/*{ console.log(strutturaSel) }*/}

                            <div className='sottoSelettore' id="struttura" style={{fontSize: "85%", width: "auto", minWidth: "10em"}}>
                                {ListaStrutture.map((struttura, i) => {
                                    return (
                                        <div id="Lista" className='row' key={i}
                                             onClick={() => {
                                                 setStruttura(JSON.stringify(struttura));
                                                 localStorage.setItem("struttura", JSON.stringify(struttura));
                                                 resetfilter();
                                             }}>
                                            <p>{struttura.configurator_name}</p>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                    <div className='headerDX'>
                        <div className='selettore selettoreFlex selettoreHeader' style={{width: "100%"}} ref={divRef}
                             onClick={() => openSottoSel("account")}>
                            <div>
                                <p style={{textAlign: "left"}}>{accountData == null ? props.accountInfo.account.name : accountData.email}</p>
                            </div>
                            <img src={arrow} alt='arrow' className='arrow'/>
                            <div className='sottoSelettore' id="account" onClick={() => Slogga()}>
                                <div className='row'>
                                    <p className='logout'>Logout</p>
                                    <img src={logout} alt='logout'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Body" className='w-100'>

                        <div className="row justify-content-space-between" style={{ paddingTop: "1.5rem" }}>
                            <div className="margin-left-2rem">
                                <div className='selettoreinBody selettoreFlex' style={{ position: "relative", padding: "0.5rem" }} ref={divRefStato}
                                    onClick={() => openSottoSel("stato")}>
                                    <p>{statoSel === null ? "Seleziona stato" : "Filtro Stato : " + JSON.parse(statoSel).nome}</p>&nbsp;
                                    <img src={arrow} alt='arrow' />
                                    <div className='sottoSelettore' id="stato" style={{ fontSize: "85%" }}>
                                        {ListaStati.map((stato, i) => {
                                            return (
                                                <div id="Lista" className='row' key={i} onClick={async () => {
                                                    setStato(JSON.stringify(stato));
                                                    localStorage.setItem("stato", JSON.stringify(stato));
                                                    window.location.reload()
                                                }}>
                                                    <p>{stato.nome}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='selettoreinBody selettoreFlex' style={{ position: "relative", padding: "0.5rem" }} ref={divRefTipostanza}
                                    onClick={() => openSottoSel("tipostanza")}>
                                    <p>{tipostanzaSel === null ? "Seleziona tipo stanza" : "Filtro Stanza : " + JSON.parse(tipostanzaSel).nome}</p>&nbsp;
                                    <img src={arrow} alt='arrow' />
                                    <div className='sottoSelettore' id="tipostanza" style={{ fontSize: "85%" }}>
                                        {ListaTipiStanza.map((tipostanza, i) => {
                                            return (
                                                <div id="Lista" className='row' key={i} onClick={async () => {
                                                    setTipostanza(JSON.stringify(tipostanza));
                                                    localStorage.setItem("tipostanza", JSON.stringify(tipostanza));
                                                    window.location.reload()
                                                }}>
                                                    <p>{tipostanza.nome}</p>

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                {JSON.parse(strutturaSel).name !== "All"
                                    && JSON.parse(strutturaSel).name !== null
                                    && JSON.parse(strutturaSel).name !== undefined
                                    && JSON.parse(strutturaSel).name !== "undefined"
                                    && JSON.parse(strutturaSel).name !== ""
                                    ? 
                                    <div className='selettoreinBody selettoreFlex' style={{ position: "relative", padding: "0.5rem" }} ref={divRefPiano}
                                        onClick={() => openSottoSel("piano")}>
                                        <p>{pianoSel === null ? "Seleziona piano" : "Filtro Piano : " + JSON.parse(pianoSel).id}</p>&nbsp;
                                        <img src={arrow} alt='arrow' />
                                        <div className='sottoSelettore' id="piano" style={{ fontSize: "85%" }}>
                                            {ListaPiani.map((piano, i) => {
                                                return (
                                                    <div id="Lista" className='row' key={i} onClick={async () => {
                                                        setPiano(JSON.stringify(piano));
                                                        localStorage.setItem("piano", JSON.stringify(piano));
                                                        window.location.reload()
                                                    }}>
                                                        <p>{piano.id}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div> 
                                    : 
                                    null
                                }

                                { 
                                    timeoutFetch
                                    ?
                                    <div className='selettoreinBody selettoreFlex' style={{ position: "relative", top: "50%", color: "orange"}}>
                                        update in {timeoutFetch} secondi
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            <div className="margin-right-2rem div-cont">
                                <div className='selettoreinBody displayContent'
                                    style={{ position: "relative", right: "6%", top: "3px", width: "auto", padding: "1rem" }}>
                                    <div className='dot red medium' style={{ position: "absolute", left: "10px" }}></div>
                                    <p style={{ marginLeft: "0.4rem" }} >{numeroDevices === null ? "nessun dispositivo" : offlineDevices}</p>&nbsp;
                                </div>
                                <div className='selettoreinBody displayContent'
                                    style={{ position: "relative", right: "6%", top: "3px", width: "auto", padding: "1rem" }}>
                                    <div className='dot green medium' style={{ position: "absolute", left: "10px" }}></div>
                                    <p style={{ marginLeft: "0.4rem" }} >{numeroDevices === null ? "nessun dispositivo" : onlineDevices}</p>&nbsp;
                                </div>
                            </div>
                        </div>

                    <div id="Popup">
                        <div className="popupContent">
                            <PopupComp info={infosPopup} closePopup={closePopup} Logout={Logout}
                                       SchedaInScheda={SchedaInScheda} loading={props.loading} getDash={getDash}
                                       mslogout={props.logout} refreshLogin={refreshLogin}/>
                        </div>
                    </div>
                    <div id="ContentPage" style={{display:"flex"}} className="container" >
                        <div className="row justify-content-center page-h w-100">
                                {devices.map((sensor) => (
                                    <SensoriComp key={sensor.id} sensor={sensor} openPopup={openPopup2} logout={Logout}
                                        onlineTimestamp={timestamp(sensor.onlineTimestamp)}
                                        offlineTimestamp={timestamp(sensor.offlineTimestamp)}
                                        mac={sensor.mac}
                                        token={props.accountInfo.jwtIdToken} mqtt={mqttclient}
                                        mslogout={props.logout} serial={sensor.serial} refreshLogin={refreshLogin} />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
