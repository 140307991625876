import {url} from '../config';

const dipasApi = {

    getStrutture: async (token) => {
        const response = await fetch(`${url}strutture`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
        });
        if (response.status === 200) {
            return await response.json();
        }
        else if (response.status === 401) {
            return { error: 'Unauthorized' };
        } else {
            return false;
        }
    },

    getPiani: async (token, db) => {
        const response = await fetch(`${url}piani/${db}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
        });
        if (response.status === 200) {
            return await response.json();
        }
        else if (response.status === 401) {
            return { error: 'Unauthorized' };
        } else {
            return false;
        }
    },

}

export default dipasApi;