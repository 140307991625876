import {url} from '../config';
const sensor = {
    getSensors: async (token, struttura, piano, stanza, stato) => {
        try {
            if (struttura === "Non assegnati") {
                struttura = undefined;
            }
            //console.log(struttura, piano, stanza, stato);
            //manage the filter if present
            let urlFilter = "";
                urlFilter = "?struttura=" + struttura + "&piano=" + piano + "&stanza=" + stanza + "&stato=" + stato;
            //console.log(urlFilter);
            //get request to the server
            console.log(url + "sensors" + urlFilter);
            console.log(token);
            const response = await fetch(url + "sensors" + urlFilter, {
                method: 'GET', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            
            });
            if (response.status === 200) {
                return await response.json();
            }
            else if (response.status === 401) {
                return { error: 'Unauthorized' };
            } else if (response.status === 404) {
                return { error: 'Not found' };
            }
        }
        catch (error) {
            console.log(error);
            //return false;
        }
        
    },
    getSensor:async(token, sensor_id) => {
        try {
            //get request to the server
            const response = await fetch(url + "sensor/" + sensor_id, {
                method: 'GET', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            });
            if (response.status === 200) {
                return await response.json();
            }
            else if (response.status === 401) {
                return { error: 'Unauthorized' };
            } else if (response.status === 404) {
                return { error: 'Not found' };
            }
        }
        catch (error) {
            console.log(JSON.parse(error));
            //return false;
        }
        
    },
    getSensorConfig: async(token, sensor_id) => {
        const response = await fetch(url + "config/" + sensor_id, {
            method: 'GET', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return false;
        }
    },

    getsensorLocation: async(token, location) => {
        const response = await fetch(url + "location/" + location, {
            method: 'GET', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return false;
        }
    },
                


    addSensor: (token, req, res) => {
        fetch(url + "sensor", {
            method: 'POST', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate Content-Type
            // Add any other headers if required
            },
            body: JSON.stringify({ key: 'value' }) // Set the request body
            })
            .then(response => {
            if (response.ok) {
                return response.json(); // Parse the response data as JSON
            } else {
                throw new Error('Request failed'); // Handle non-2xx HTTP status codes
            }
        })
        .then(data => {
            // Process the response data
            console.log(data);
        })
        .catch(error => {
            // Handle any errors that occurred during the request
            console.error(error);
        });
    },
    editSensorConfig: async(token, sensor_id, data) => {
        const response = await fetch(url + "config/" + sensor_id, {
            method: 'POST', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate Content-Type

            },
            body: JSON.stringify(data) // Set the request body
            })
        if (response.status === 200) {
            return true // Parse the response data as JSON
        } else if (response.status === 401) {
            return "unauthorized";
        } else {
            return false
        }

    },


    deleteSensor: (token, req, res) => {
        fetch(url + "sensor", {
            method: 'DELETE', // or 'GET', 'PUT', 'DELETE', etc.
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate Content-Type
            // Add any other headers if required
            },
            body: JSON.stringify({ key: 'value' }) // Set the request body
            })
            .then(response => {
            if (response.ok) {
                return response.json(); // Parse the response data as JSON
            } else {
                throw new Error('Request failed'); // Handle non-2xx HTTP status codes
            }
        })
        .then(data => {
            // Process the response data
            console.log(data);
        })
        .catch(error => {
            // Handle any errors that occurred during the request
            console.error(error);
        });
    },
}


export default sensor;