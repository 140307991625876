const GRAFANA_URL = 'https://configurator.care4u.it';
const API_KEY = 'glsa_Ew7lpFzgHiw57Zi7VDwzIzwWqrMnhZq9_c53d9fad';

async function createGraph(title, device_id, folder) {
    const dashboardConfig = {
        dashboard: {
            id: null,
            title: title,
            tags: ["templated"],
            timezone: "browser",
            schemaVersion: 16,
            version: 1,
            refresh: "5s",
            panels: [{
                type: "graph",
                title: "PresenceRegionMap + typeEvents",
                gridPos: {
                    x: 0,
                    y: 0,
                    w: 12,
                    h: 6
                },
                datasource: "influxdb",
                targets: [{
                    rawQuery: true,
                    query: `from(bucket: \"VayyarLog\")\n |> range(start: v.timeRangeStart, stop: v.timeRangeStop)\n |> filter(fn: (r) => r._measurement == \"mqtt_consumer\" and r.topic == \"/devices/${device_id}/events\"  and (r._field == \"payload_presenceRegionMap_0\" or r._field == \"type\") )`,
                    interval: "5s",
                    maxDataPoints: "auto"
                }]
            }],
            time: {
                from: "now-6h",
                to: "now"
            },
            timepicker: {
                refresh_intervals: ["5s", "10s", "30s", "1m", "5m", "15m", "30m", "1h", "2h", "1d"]
            }
        },
        folderUid: folder,
        message: "Made changes to xyz",
        overwrite: true,
        inputs: [{
            name: "DS_Grafana",
            type: "datasource",
            pluginId: "grafana",
            value: "grafana_datasource_name"
        }]
    };

    // Wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
        const response = await fetch(`${GRAFANA_URL}/api/dashboards/db`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${API_KEY}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dashboardConfig)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error('Error creating dashboard: ' + JSON.stringify(errorData));
        }

        const responseData = await response.json();
        console.log('Dashboard created successfully:', responseData);
    } catch (error) {
        console.error(error);
    }
}

async function createFolder(name) {
    // Generate uid (must be unique and random)
    const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const folderConfig = {
        uid: uid,
        title: name
    };

    try {
        const response = await fetch(`${GRAFANA_URL}/api/folders`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${API_KEY}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(folderConfig)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error('Error creating folder: ' + JSON.stringify(errorData));
        }

        const responseData = await response.json();
        console.log('Folder created successfully:', responseData);
        return uid;
    } catch (error) {
        console.error(error);
    }
}

async function getFolderIds() {
    try {
        const response = await fetch(`${GRAFANA_URL}/api/folders`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${API_KEY}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error('Error getting folders: ' + JSON.stringify(errorData));
        } else {
            const responseData = await response.json();
            console.log('Folders retrieved successfully:', responseData);
            return responseData;
        }

        
        
        
    } catch (error) {
        console.error(error);
    }
}

async function getFolderId(device_id) {
    try {
        const folders = await getFolderIds();
        console.log(folders);
        let id = null;

        folders.forEach(folder => {
            if (folder.title === device_id) {
                id = folder.uid;
            }
        });

        return id;
    } catch (error) {
        console.error(error);
    }
}

export default { createGraph, createFolder, getFolderId, getFolderIds };
