import { MsalAuthProvider, LoginType } from 'react-aad-msal';
 
// Msal Configurations
const config = {
    auth: {
      clientId: '00135a3c-0231-4d7f-a1e3-5464a4e0d6e6',
      authority: 'https://login.microsoftonline.com/26a624d7-7d04-4987-b464-ca37b6eb87a7',
      redirectUri: process.env.REACT_APP_URL // Make sure this matches your app's configuration, //'https://configurator.care4u.it',
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
    forceRefresh: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
