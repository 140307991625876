import React from 'react';
//import { Websocket } from 'react-ultimate';

const  LogDisplay = (props) => {
    const [logs, setLogs] = React.useState([]);

    const handleLogMessage = (message) => {
    // Add the received log message to the logs state
    setLogs((prevLogs) => [...prevLogs, message]);
    };
    const subscribelogs = () => {
        props.mqttclient.subscribe("/devices/" + props.device + "/logs");
        props.mqttclient.onMessageArrived = (message) => {
            if (message.destinationName === "/devices/" + props.device + "/logs") {
                handleLogMessage(message.payloadString);
            }
        }
    };

    React.useEffect(() => {
        // Subscribe to the log topic
        //mqttclient.subscribe("/devices/" + props.device + "/logs");
        //mqttclient.onMessageArrived = (message) => {
        //    if (message.destinationName === "/devices/" + props.device + "/logs") {
        //        handleLogMessage(message.payloadString);
        //    }
        //}
        if (logs === undefined || logs.length === 0) {
            setLogs(["No logs available...."]);
        }

        subscribelogs();
        return () => {
        // Unsubscribe from the log topic
        //mqttclient.unsubscribe("/devices/" + props.device + "/logs");
        };
    }, []);

    return (
    <div style={{overflow:"auto", width:"100%"}}>
        <h2>Log Messages</h2>
        <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize:"10px,", overflowX: "auto", backgroundColor:"black", color:"white", height:"600px", width: "98%"}}>
            {logs.join('\n')}
        </div>
    </div>
    );
};

export default LogDisplay;
