import React from 'react';
//import './App.css';
import './styles/style.css';
import Home from './pages/Home';
import Login from './pages/Login/Login';
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';

import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(Main);

function Main() {
    
    return (
      
      <>
   <AzureAD provider={authProvider} >
    {
        ({login, logout, authenticationState, error, accountInfo, istance}) => {
            switch (authenticationState) {
                case AuthenticationState.Authenticated:
                //console.log(istance)
                localStorage.setItem("login", true);
                localStorage.setItem("account", JSON.stringify({email : accountInfo.account.name}));
                localStorage.setItem("token", accountInfo.jwtIdToken);
                return (
                    <Home logout={logout} accountInfo={accountInfo} login={login} />
                );
                case AuthenticationState.Unauthenticated:
                if (localStorage.getItem("token") === "expired") {
                    login()
                    localStorage.setItem("token", accountInfo.jwtIdToken);
                    //window.location.reload();
                } else {
                    return (
                        <Login login={login}/>
                );
                }
                case AuthenticationState.InProgress:
                    return (<p>Authenticating...</p>);
                default:

                    return (<Login login={login}/>);
            }
        }
    }
    </AzureAD>
        </>
        
    );
  }

function App() {
  return <ClearCacheComponent />;
}


export default App;
